import React, { Suspense, useMemo } from 'react';
import './App.less';
import { makeStyles } from '@material-ui/core';
import { HashRouter } from 'react-router-dom';
import { ConnectionProvider } from './utils/connection';
import { WalletProvider } from '@solana/wallet-adapter-react';
import { GlobalStyle } from './global_style';
import { Spin } from 'antd';
import ErrorBoundary from './components/ErrorBoundary';
import { Routes } from './routes';
import { PreferencesProvider } from './utils/preferences';
import { ReferrerProvider } from './utils/referrer';
import { SnackbarProvider } from 'notistack';
import {
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  ExodusWalletAdapter,
  GlowWalletAdapter,
  BitpieWalletAdapter,
  HuobiWalletAdapter,
  NightlyWalletAdapter,
  BackpackWalletAdapter,
} from '@solana/wallet-adapter-wallets';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';

require('@solana/wallet-adapter-react-ui/styles.css');

const useStyles = makeStyles(() => ({
  notificationContainer: {
    marginTop: 63,
  },
}));

// Fix charting library "DEFAULT_SYMBOL is undefined" error
globalThis.DEFAULT_SYMBOL = '';

export default function App() {
  const styles = useStyles();

  const wallets = useMemo(
    () => [
      new PhantomWalletAdapter(),
      new SolflareWalletAdapter(),
      new BackpackWalletAdapter(),
      new GlowWalletAdapter(),
      new NightlyWalletAdapter(),
      new ExodusWalletAdapter(),
      new BitpieWalletAdapter(),
      new HuobiWalletAdapter(),
    ],
    [],
  );

  return (
    <Suspense fallback={() => <Spin size="large" />}>
      <GlobalStyle />
      <ErrorBoundary>
        <ConnectionProvider>
          <ReferrerProvider>
            <WalletProvider autoConnect wallets={wallets}>
              <WalletModalProvider>
                  <PreferencesProvider>
                    <SnackbarProvider
                      maxSnack={5}
                      autoHideDuration={4000}
                    // classes={{
                    //   containerRoot: styles.notificationContainer
                    // }}
                    // anchorOrigin={{
                    //   vertical: 'bottom',
                    //   horizontal: 'right',
                    // }}>
                    >
                      <Suspense fallback={() => <Spin size="large" />}>
                        <HashRouter basename={'/'}>
                          <Routes />
                        </HashRouter>
                      </Suspense>
                    </SnackbarProvider>
                  </PreferencesProvider>
              </WalletModalProvider>
            </WalletProvider>
          </ReferrerProvider>
        </ConnectionProvider>
      </ErrorBoundary>
    </Suspense>
  );
}