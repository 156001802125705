import { Widget } from '@typeform/embed-react';
import React from 'react';

export default function TokenListingPage() {

  return (
    <Widget id="rkzcIVLE" style={{ height: 800, width: '100%', paddingTop: 0, paddingBottom: 0, paddingLeft: 0, paddingRight: 0, display: 'flex', alignItems: '' }} />
    
  )
}
