import React from 'react';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';

import OpenOrdersPage from './pages/OpenOrdersPage';
import TradePage from './pages/TradePage';
import SwapPage from './pages/SwapPage';
import BalancesPage from './pages/BalancesPage';
import ListNewMarketPage from './pages/ListNewMarketPage';
import PastAirdropsPage from './pages/PastAirdrops';
import HomePage from './pages/HomePage';
import TokenomicsPage from './pages/Tokenomics';
import MarketsPage from './pages/MarketsPage';
import TokenListingPage from './pages/TokenListing';
import HelpPage from './pages/HelpPage';


import BasicLayout from './components/BasicLayout';
import { getTradePageUrl } from './utils/markets';
import usePageTracking from './utils/solape-custom/usePageTracking';


export function Routes() {
  usePageTracking();

  return (
    <>

      <Switch>
        <HashRouter basename={'/'}>
          <BasicLayout>
            <Route exact path="/">
              <Redirect to={getTradePageUrl()} />
            </Route>
            <Route exact path="/market/:marketAddress">
              <TradePage />
            </Route>
            <Route exact path="/orders" component={OpenOrdersPage} />
              <Route exact path="/balances" component={BalancesPage} />
              <Route exact path="/swap" component={SwapPage} />
              <Route exact path="/about" component={HomePage} />
              <Route exact path="/markets" component={MarketsPage} />
              <Route exact path="/past-airdrops" component={PastAirdropsPage} />
              <Route exact path="/tokenomics" component={TokenomicsPage} />
              <Route exact path="/token-listing" component={TokenListingPage} />
              <Route exact path="/help" component={HelpPage} />
              <Route exact path="/list-new-market" component={ListNewMarketPage} />
          </BasicLayout>

      </HashRouter>
      </Switch>

    </>
  );

}
