import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, makeStyles, Typography } from "@material-ui/core";
import ToggleButton from '@mui/material/ToggleButton';
import Skeleton from '@mui/material/Skeleton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as StarIcon } from "../assets/img/StarIcon.svg"
import { ReactComponent as StarIconFavorite } from "../assets/img/StarIconFavorite.svg"
import { useLocalStorageState } from '../utils/utils';
import { addOrRemoveFavorite } from '../utils/favoriteMarkets';

import { Sparklines, SparklinesLine } from "react-sparklines";

import { useConnection } from '../utils/connection';
import { PublicKey } from '@solana/web3.js';

import TokenMints from "../config/token-mints.json"
import Markets from "../config/markets.json"
import Logos from "../config/logos.json"
import cgToken from "../config/last-coins.json"

import {
  TokenListProvider,
  TokenInfo
} from "@solana/spl-token-registry";

import TokenIcon from "../components/Common/TokenIcon";
import SearchBar from "../components/Common/SearchBar";
import { Table } from 'antd';
import { getToken, getAllToken } from '../utils/solape-custom/tokenApi';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
const { Column } = Table;


// https://docs.google.com/spreadsheets/d/1T4bqrNqUHFbN4JkWyZaRaTAoTtjQAdbNmLpeebtwf5Q/edit#gid=0


const useStyles = makeStyles(_ => ({
  root: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center"
  },
  marketHeader: {
    width: "100%",
    justifyContent: "center",
    background: `repeating-linear-gradient(
      -45deg,
      #1C2222,
      #1C2222 10px,
      #4F381B 10px,
      #4F381B 12px
    )`
  },
  mainTitle: {
    marginTop: "40px",
    marginBottom: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "32px",
    lineHeight: "48px"
  },
  filters: {
    display: "flex",
    marginBottom: 24,
    justifyContent: "center"
  },
  search: {

  },
  favoritesToggle: {
    backgroundColor: '#121616',
    borderRadius: 8,
    padding: 8
  },
  toggleButton: {
    borderRadius: 8
  },
  tableRoot: {
    width: '100%',
    padding: "0 25px",
  },
  nameColumn: {
    display: "flex",
    alignItems: "center"
  },
  tokenName: {
    display: "flex",
    flexDirection: "column",
    marginLeft: 16
  },
  row: {
    height: 72
  },
  col: {
    height: 72,
    padding: '14px !important',
    borderBottom: 'none !important',
    fontSize: 16,

    '.ant-table-thead > tr > &': {
      backgroundColor: '#000',
      color: '#676767',
      fontSize: 16,
      fontWeight: 600,

      '&::before': {
        display: 'none'
      }
    },
  },
  oddRow: {
    height: 72,
    backgroundColor: "#000"
  },
  evenRow: {
    height: 72,
    backgroundColor: "#121616"
  },
  alignRight: {
    textAlign: 'right',

    '.ant-table-thead &': {
      textAlign: 'right'
    }
  },
  deltaPositive: {
    color: "#0AD171",
    display: "flex",
    justifyContent: "end"
  },
  deltaNegative: {
    color: "#FD499D",
    display: "flex",
    justifyContent: "end"
  },
  dataDailyIndicator: {
    color: '#FFCB99',
    fontSize: 11,
    lineHeight: "15px",
    background: "#2E3838",
    borderRadius: 2,
    padding: "2px 3px",
    marginRight: "6px"
  }
}));

interface Market {
  key: String;
  baseToken: TokenInfo;
  pair: String;
  price: String | null;
  delta: String | null;
  volume: String | null;
  marketCap: Number | null;
}

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    color: '#676767',
    '&.Mui-selected': {
      color: '#FFF',
      background: "linear-gradient(100.61deg, #FF810A 0%, #FFAB5C 100%)"
    },
    '&.Mui-disabled': {
      border: 0,
    },
    '&:not(:first-of-type)': {
      borderRadius: theme.shape.borderRadius,
    },
    '&:first-of-type': {
      borderRadius: theme.shape.borderRadius,
    },
  },
}));

const currencyConfig = {
  style: 'currency',
  currency: 'USD',
  useGrouping: true,
};
const currencyFormat = new Intl.NumberFormat('en-US', currencyConfig);
const longCurrencyFormat = new Intl.NumberFormat('en-US', {
  ...currencyConfig,
  minimumFractionDigits: 6,
});

const LinearGradientFill = () => {
  return (
    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" stopColor="rgb(255, 129, 10, 0.2)" stopOpacity="1" />
      <stop offset="100%" stopColor="255, 171, 92, 0" stopOpacity="0" />
    </linearGradient>
  );
};


export default function MarketsPage() {

  const styles = useStyles();
  const [tokens, setTokens] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [markets, setMarkets] = useState(Array<Market>());
  const [displayFavoritesOrAll, setDisplayFavoritesOrAll] = useState("all");
  const [filterText, setFilterText] = useState('');
  const [sortByFavorite, setSortByFavorite] = useState(false);

  const connection = useConnection();

  // favoriteMarkets is a long string with format: [market-A][market-B][market-C]
  // These are the addresses of the markets favorited by the user
  // Toggling the star icon next to each market updates this string
  const [favoriteMarkets, setFavoriteMarkets] = useLocalStorageState('favoriteMarkets', "")

  useEffect(() => {
    // Build tokens map
    const solapeTokens = {}
    TokenMints.forEach(t => {
      solapeTokens[t.name] = true
    });

    console.log('TokenMints: ', TokenMints.length);

    (async function getTokenData() {

      // Only show SolApe supported tokens
      let tokenList = await new TokenListProvider().resolve();

      const serumTokens = {}
      tokenList.getList().forEach(t => {
        serumTokens[t.symbol] = t
      })

      let fullTokenList = new Map<String, TokenInfo>()

      // Get Serum tokens that are in Solape
      tokenList.getList().forEach(t => {

        // This is a serum AND Solape token
        if (solapeTokens[t.symbol] && t.chainId === 101) {

          let serumUsdcAddr = t.extensions?.serumV3Usdc
          let serumUsdtAddr = t.extensions?.serumV3Usdt

          if (!serumUsdcAddr) {
            const usdcMarket = Markets.find(m => m.name === `${t.symbol}/USDC` && !m.deprecated)
            serumUsdcAddr = usdcMarket ? usdcMarket?.address : undefined
          }

          if (!serumUsdtAddr) {
            const usdtMarket = Markets.find(m => m.name === `${t.symbol}/USDT` && !m.deprecated)
            serumUsdtAddr = usdtMarket ? usdtMarket.address : undefined
          }

          fullTokenList[t.symbol] = {
            name: t.name,
            decimals: t.decimals,
            logoURI: t.logoURI || Logos[t.symbol],
            symbol: t.symbol,
            address: t.address,
            chainId: 101,
            tags: t.tags,
            extensions: {
              serumV3Usdc: serumUsdcAddr,
              serumV3Usdt: serumUsdtAddr
            }
          }
        }
      })

      // Get Solape tokens that aren't in serum
      const solapeOnlyTokens = TokenMints.filter(t => !serumTokens[t.name])
      solapeOnlyTokens.forEach(t => {

        const usdcMarket = Markets.find(m => m.name === `${t.name}/USDC` && !m.deprecated)
        const usdtMarket = Markets.find(m => m.name === `${t.name}/USDT` && !m.deprecated)

        fullTokenList[t.name] = {
          name: t.name,
          address: t.address,
          decimals: t.decimals || 9,
          chainId: 101,
          symbol: t.name,
          logoURI: Logos[t.name],
          extensions: {
            serumV3Usdc: usdcMarket ? usdcMarket.address : undefined,
            serumV3Usdt: usdtMarket ? usdtMarket.address : undefined
          }
        }

      })

      setTokens(fullTokenList)

      const allMarkets = new Array<Market>()

      await Markets.forEach(m => {
        const assets = m.name.split("/")
        const baseTokenSymbol = assets[0]

        const baseTokenInfo = fullTokenList[baseTokenSymbol] || {}
        baseTokenInfo.parentMarket = m.address

        if (allMarkets.find((market: any) => market.key === m.name)) {
          return
        }

        let market = {
          key: m.name,
          baseToken: baseTokenInfo,
          pair: m.name,
          price: null,
          high_24h: null,
          low_24h: null,
          delta: null,
          sparkline: null,
          volume: null,
          marketCap: null
        }

        allMarkets.push(market);
      })

      let allMarketsIds = allMarkets.map(am => {
        let coinSymbol = am.baseToken.symbol;
        let [filteredCgToken] = cgToken.filter(cgt => {
          return cgt.symbol === (coinSymbol && coinSymbol.toLowerCase())
        });
        if (filteredCgToken) {
          return filteredCgToken.id;
        }
      });

      let allTokensInfo;
      try {
        allTokensInfo = await getAllToken(allMarketsIds);
      } catch (e) {
        console.log(e);
        return
      }

      let allMarketsUpdate = new Array<Market>();
      let failedCoinsList = new Array<Market>();

      Markets.map(async m => {
        const assets = m.name.split("/");
        const baseTokenSymbol = assets[0];

        if (assets[1] !== "USDC") {
          return;
        }

        const baseTokenInfo = fullTokenList[baseTokenSymbol] || {};
        baseTokenInfo.parentMarket = m.address;

        if (allMarketsUpdate.find((market: any) => market.key === m.name)) {
          return
        }

        // Get CoinGecko data
        let coinAddress = baseTokenInfo.address;

        let [filteredCgToken] = cgToken.filter(cgt => {
          return cgt.platforms.solana === coinAddress
        });

        if (!filteredCgToken) {
          return;
        }

        let market;
        let [tokenInfo] = allTokensInfo.filter(at => at.id === filteredCgToken.id);

        try {
          if (
            !tokenInfo.current_price ||
            !tokenInfo.high_24h ||
            !tokenInfo.low_24h ||
            !tokenInfo.price_change_percentage_24h ||
            !tokenInfo.sparkline_in_7d.price ||
            !tokenInfo.total_volume
          ) {
            throw new Error('ERROR: missing data in API response for this coin.')
          }

          market = {
            key: m.name,
            baseToken: baseTokenInfo,
            pair: m.name,
            price: tokenInfo.current_price,
            high_24h: tokenInfo.high_24h,
            low_24h: tokenInfo.low_24h,
            delta: tokenInfo.price_change_percentage_24h,
            sparkline: tokenInfo.sparkline_in_7d.price,
            volume: tokenInfo.total_volume,
            marketCap: tokenInfo.market_cap || 0
          }

          if (tokenInfo.market_cap === 0) {
            failedCoinsList.push(market);
          }
        } catch (e) {
          return;
        }

        allMarketsUpdate.push(market)
      });

      setMarkets(allMarketsUpdate);
      setIsDataLoading(false);

      //@ts-ignore
      let failedCoinsAddresses = failedCoinsList.map(fc => {
        return new PublicKey(fc.baseToken.address);
      });

      let updateList: any = [];

      await failedCoinsAddresses.reduce(async (promise, failed, index) => {
        await promise;

        let mintinfo = await connection.getTokenSupply(failed);
        // console.log('mintinfo.value: ', mintinfo.value);
        //@ts-ignore
        let supply = parseInt(mintinfo.value.amount) / 10 ** mintinfo.value.decimals;
        //@ts-ignore
        let marketCap = supply * failedCoinsList[index].price;

        let indexOf = allMarketsUpdate.findIndex(amu => {
          return amu.baseToken.address === failed.toBase58()
        });
        updateList.push([
          indexOf,
          marketCap
        ])
      }, Promise.resolve())

      console.log('update list: ', updateList.length);

      setMarkets(localMarkets => {
        let items = [...localMarkets];

        updateList.forEach(([indexOf, marketCap]) => {
          let item = {
            ...items[indexOf],
            marketCap
          }
          items[indexOf] = item;
        });

        return items;
      });
    })();

    console.log('ALL DONE');

  }, []);

  let filteredMarkets = filterText !== ''
    ? markets.filter(m => {
      return (m.baseToken.name || '').toLowerCase().includes(filterText) || (m.pair || '').toLowerCase().includes(filterText)
    })
    : markets;

  if (sortByFavorite) {
    filteredMarkets = filteredMarkets.sort((a: any, b: any) => {
      return (
        favoriteMarkets.includes(a.baseToken.parentMarket)
        ===
        favoriteMarkets.includes(b.baseToken.parentMarket)
      )
        ? 0
        : favoriteMarkets.includes(a.baseToken.parentMarket)
          ? -1
          : 1;
    });
  }

  console.log('Sort by fav : ', sortByFavorite);

  const defaultSortOrder: "descend" | "ascend" | null | undefined = "descend";

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      className={styles.root}
    >
      <Grid item xs={12} className={styles.marketHeader}>
        <h1 className={styles.mainTitle}>Markets</h1>
        <div className={styles.filters}>
          {/* <div className={styles.favoritesToggle}>
            <StyledToggleButtonGroup
              color="primary"
              value={displayFavoritesOrAll}
              exclusive
              onChange={(_, newSetting) => setSortByFavorite(!sortByFavorite)}
            >
              <ToggleButton value="all">All</ToggleButton>
              <ToggleButton value="favorites">Favorites</ToggleButton>
            </StyledToggleButtonGroup>
          </div> */}
          <div className={styles.search}>
            <SearchBar filterText={filterText} setFilterText={(e) => setFilterText(e.target.value)} />
          </div>
        </div>
      </Grid>

      <Grid item xs={12} style={{ width: '100%' }}>
        <div className={styles.tableRoot}>
          {markets &&
            <Table
              loading={isDataLoading}
              dataSource={filteredMarkets}
              pagination={{ pageSize: 25, position: ['bottomRight'] }}
              rowClassName={(_, index) => {
                return index % 2 === 0 ? styles.evenRow : styles.oddRow
              }}>
              <Column
                title={() => <p style={{ margin: 0, padding: 8, display: "flex" }}><StarIcon /></p>}
                dataIndex="baseToken"
                key="baseToken"
                sorter={(a: any, b: any) => {
                  return (
                    favoriteMarkets.includes(a.baseToken.parentMarket)
                    ===
                    favoriteMarkets.includes(b.baseToken.parentMarket)
                  )
                    ? 0
                    : favoriteMarkets.includes(a.baseToken.parentMarket)
                      ? -1
                      : 1;
                }}
                className={styles.col}
                render={(baseToken) => {

                  const isFavorite = favoriteMarkets.indexOf(baseToken.parentMarket) >= 0
                  const Icon = isFavorite ? StarIconFavorite : StarIcon

                  return (
                    <div className={styles.nameColumn}>
                      <IconButton onClick={() => addOrRemoveFavorite(baseToken.parentMarket, favoriteMarkets, setFavoriteMarkets)} style={{ marginRight: 16 }}>
                        <Icon />
                      </IconButton>
                    </div>
                  )
                }}
              />
              <Column
                title="Name"
                dataIndex="baseToken"
                key="baseToken"
                className={styles.col}
                render={(baseToken) => {

                  return (
                    <div className={styles.nameColumn}>
                      <TokenIcon symbol={baseToken.symbol} />
                      <div className={styles.tokenName}>
                        <Typography>{baseToken.name}</Typography>
                        <Typography><b>{baseToken.symbol}</b></Typography>
                      </div>
                    </div>
                  )
                }}
              />
              <Column
                title="Pair"
                dataIndex="pair"
                key="pair"
                className={styles.col} />
              <Column
                title="Price"
                dataIndex="price"
                key="price"
                align="right"
                className={[styles.col].join(" ")}
                render={(price) => {
                  if (price) {
                    return longCurrencyFormat.format(price)
                  } else {
                    return <Skeleton variant="text" />
                  }
                }} />
              <Column
                title={() => <>Delta <span className={styles.dataDailyIndicator}>24h</span></>}
                dataIndex="delta"
                key="delta"
                align="right"
                sorter={(a: any, b: any) => a.delta - b.delta}
                className={[styles.col].join(" ")}
                render={(delta) => {
                  if (delta >= 0) {
                    return (
                      <>
                        {delta &&
                          <span className={styles.deltaPositive}>
                            <ArrowDropUpIcon /> {delta.toFixed(2)} %
                          </span>
                        }
                        {!delta &&
                          <Skeleton variant="text" />
                        }
                      </>
                    )
                  } else {
                    return (
                      <>
                        {delta &&
                          <span className={styles.deltaNegative}>
                            <ArrowDropDownIcon /> {delta.toFixed(2)} %
                          </span>
                        }
                        {!delta &&
                          <Skeleton variant="text" />
                        }
                      </>
                    )
                  }
                }} />
              <Column
                title={() => <>Volume <span className={styles.dataDailyIndicator}>24h</span></>}
                dataIndex="volume"
                key="volume"
                align="right"
                sorter={(a: any, b: any) => a.volume - b.volume}
                className={[styles.col].join(" ")}
                render={(price) => {
                  if (price) {
                    return currencyFormat.format(price)
                  } else {
                    return <Skeleton variant="text" />
                  }
                }} />
              <Column
                title={() => <>Market Cap</>}
                dataIndex="marketCap"
                key="marketCap"
                align="right"
                sorter={(a: any, b: any) => a.marketCap - b.marketCap}
                className={[styles.col].join(" ")}
                defaultSortOrder={sortByFavorite ? undefined : defaultSortOrder}
                render={(price) => {
                  if (price) {
                    return currencyFormat.format(price)
                  } else {
                    return <Skeleton variant="text" />
                  }
                }} />
              <Column
                title="Movement"
                dataIndex="sparkline"
                key="movement"
                className={styles.col}
                render={(baseToken) => {
                  return (
                    <>
                      {baseToken &&
                        <Sparklines data={baseToken} height={44} width={192}>
                          <defs>
                            <LinearGradientFill />
                          </defs>
                          <SparklinesLine color="#F37B21" style={{ fillOpacity: "1", fill: "url(#gradient)", strokeWidth: "2" }} />
                        </Sparklines>
                      }
                      {!baseToken &&
                        <Skeleton variant="rectangular" width={192} height={44} />
                      }
                    </>
                  )
                }} />
            </Table>
          }
        </div>
      </Grid>
    </Grid>
  )
}
