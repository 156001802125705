import React, { useCallback, useEffect, useRef, useState } from 'react';
import "./HomePage.less";
import { Grid, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(_ => ({
    root: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      textAlign: "center",
      alignItems: "center"
    },
    marketHeader: {
      display: "flex",
      width: "100%",
      paddingTop: '1px',
      justifyContent: "center",
      background: "#121616",
    },
    mainTitle: {
      marginTop: "-80px",
      marginBottom: "-100px",
      width: "70%",
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "32px",
      lineHeight: "48px"
    },
    tableRoot: {
        width: '100%',
        padding: "0 25px",
      },
}));

export default function HomePage() {
    const [navExpanded, setNavExpanded] = useState<boolean>(false);
    const [activeFaq, setActiveFaq] = useState<number>(0);
    const styles = useStyles();
    const [tokens, setTokens] = useState<any>({
    });
  
    function expandNav(e) {
      e.preventDefault();
      setNavExpanded(true);
    }
  
    function collapseNav(e) {
      e.preventDefault();
      setNavExpanded(false);
    };

  return (
    <Grid
    container
    justifyContent="flex-start"
    alignItems="flex-start"
    className={styles.root}
>

    <Grid item container spacing={0} className={styles.marketHeader}>
        <Grid
            item
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
        >
            <Grid item
            className={''}
            />

      <div className="container-home">
        <div className="ghost"/>
        <img
            src="/images/HelpBackground.svg"
            alt="A guide to the jungle"
            className={styles.mainTitle}>
        </img>
            <a href="https://docs.solape.io" style={{ position: 'absolute', marginLeft: '-198px', marginTop: '5px', fontSize: '20px', fontFamily: 'Inter', color: 'white' }} className="swap">Learn more </a>

        <div className="section faq">
          <h4>Frequently Asked Questions</h4>
          {/*<p>For more information, please check our <a href="#">Help page</a>.</p>*/}
          <div className="faqs">
            <div className={"question" + (activeFaq === 0 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(0)}>
                What is SolAPE and what makes it different?
              </div>
              <div className="answer">
                SolAPE is an ecosystem driven by user-experience. We pride ourselves in offering a trading solution that is smooth and seamless while also building out interactive ways for our community to embrace cryptocurrency and DeFi.
              </div>
            </div>
            <div className={"question" + (activeFaq === 1 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(1)}>
                I’m new to DeFi, how can I get started?
              </div>
              <div className="answer">
                Here’s a great guide on the fundamentals of <a href="https://blog.coinbase.com/a-beginners-guide-to-decentralized-finance-defi-574c68ff43c4" target="_blank">Decentralized Finance</a>. In order to begin trading on SolAPE Swap, you’ll first need a small amount of SOL and USDC in your SPL wallet. This can be acquired from a centralized exchange such as FTX.
              </div>
            </div>
            <div className={"question" + (activeFaq === 2 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(2)}>
                Why build on Solana?
              </div>
              <div className="answer">
                Solana stands out in DeFi with lightning-fast speeds of up to 65,000 transactions per second, 400ms block times, and less than $0.01 average transaction fees. SolAPE are firm believers in the Solana innovation and believe it will power the future DeFi.
              </div>
            </div>
            <div className={"question" + (activeFaq === 3 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(3)}>
                Can I use SolAPE on my phone?
              </div>
              <div className="answer">
                You can access SolAPE from your mobile browser by connecting your wallet. We plan to roll out a more smartphone-friendly version later in the year.
              </div>
            </div>
            <div className={"question" + (activeFaq === 4 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(4)}>
                How can I get in touch?
              </div>
              <div className="answer">
                We are a community focused group and offer support through both our discord server and twitter account. Alternatively you can reach out to us at info@solape.io
              </div>
            </div>
            <div className={"question" + (activeFaq === 5 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(5)}>
                What does "Your SOL balance is low" mean?
              </div>
              <div className="answer">
                SOL is required to pay network (gas) fees. Fees are very low on Solana and you may be able to make a number of transactions with a low balance, however we recommend keeping at least 0.1 SOL in your wallet for gas.
              </div>
            </div>
            <div className={"question" + (activeFaq === 6 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(6)}>
                Why did my transaction fail?
              </div>
              <div className="answer">
                As users get more acquainted with DeFi usage, there may be a number of learning-curves or potential issues that can arise. Here are some generic troubleshooting solutions for failed transactions.
                <br /><br />
                Insufficient SOL: SOL is required to pay network fees (gas), we recommend keeping at least 0.1 SOL in your wallet to ensure smooth transactions.
                <br /><br />
                Incorrect Account: In some cases, users may have multiple accounts created for a single token in their wallets. Ensure that you have the correct account selected in the DEX when making trades.
                <br /><br />
                Approving Transactions: If you see the “Making Transaction” notification in the lower left-hand corner of your screen, you will need to approve the transaction in your SPL wallet.
              </div>
            </div>
          </div>
        </div>
      </div>
      </Grid>
    </Grid>
    </Grid>

  );
};