import React from "react";
import { Hidden, Box, Grid, makeStyles, Button, IconButton } from "@material-ui/core";
import FileCopy from '@material-ui/icons/FileCopy';

import {
    MarketProvider,
    useMarket,
    useMarkPrice
} from '../utils/markets';

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "0 auto",
        maxWidth: "100%",
        width: "1200px",
        flexDirection: "column",
        textAlign: "left",
        padding: "0 16px",
        fontFamily: "Inter",
    },
    mainTitle: {
        margin: "0",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: "40px",
        lineHeight: "48px"
    },
    exchangesListItem: {
        background: "#1C2222",
        borderRadius: "8px",
        margin: 0,
        display: "flex",
        flex: 1,
        height: "100%",
        padding: "9px 16px"
    },
    logosSpacer: {
        flex: 1
    },
    header: {
        margin: "32px 0 24px"
    },
    solapeTokenCard: {
        background: "#1C2222",
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.5)",
        borderRadius: "8px",
        height: "100%",
    },
    solapeTokenCardHeader: {
        background: "#121616",
        padding: "24px",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    },
    solapeTokenCardItem: {
        padding: "24px"
    },
    solapeTokenCardItemChartPadding: {
        padding: "24px 16px"
    },
    solapeTokenCardHeaderTitle: {
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 800,
        margin: 0
    },
    solapeTokenCardHeaderImage: {
        marginRight: "14px",
        display: "inline-block",
        verticalAlign: "sub",
        height: "32px"
    },
    solapeTokenCardHeaderPrice: {
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 800,
        margin: 0,
        textAlign: "right"
    },
    solapeTokenCardTitle: {
        fontSize: "20px",
        lineHeight: "32px",
        margin: 0,
        color: "#b1b1b1",
        marginBottom: "8px"
    },
    solapeTokenCardMetric: {
        fontSize: "32px",
        lineHeight: "40px",
        margin: 0,
        overflowWrap: "anywhere"
    },
    solapeTokenCardMetricMarket: {
        fontSize: "24px",
        lineHeight: "32px",
        fontWeight: 600
    },
    solapeTokenCardTitleMarket: {
        fontSize: "20px",
        lineHeight: "32px",
        marginBottom: "4px"
    },
    walletId: {
        background: "#121616",
        borderRadius: "8px",
        padding: "8px",
        maxWidth: "100%"
    },
    walletCopyId: {
        flex: 1,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        margin: 0,
        width: "1px"
    },
    walletIdCopyIcon: {
        padding: "5px",
        marginLeft: "4px",
        borderRadius: "50%",
        background: "linear-gradient(100.61deg, #B85900 0%, #FF810A 100%)",
        color: "#fff",
        width: "26px",
        height: "26px",
        "& svg": {
            height: "16px"
        }
    },
    buySolapeButton: {
        marginTop: "16px",
        width: "100%",
        background: "linear-gradient(100.61deg, #B85900 0%, #FF810A 100%)",
        borderRadius: "8px"
    },
    solapeFullMarketCap: {
        // fontFamily: "Inter"
        fontWeight: 600,
        backgroundColor: "#f3ec78",
        backgroundImage: "linear-gradient(100.61deg, #FF810A 0%, #FFAB5C 100%)",
        backgroundSize: "100%",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        color: "transparent"
    },
    coinDistributionChart: {
        maxWidth: "100%"
    },
    solapeCoinDistributionCard: {
        height: "100%",
        background: "#121616",
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.5)",
        borderRadius: "8px"
    },
    centeredMetrics: {
        textAlign: "center"
    },
    solapeHistoryCard: {
        height: "100%",
        background: "#121616",
        boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.5)",
        borderRadius: "8px"
    },
    solapeHistoryCardTitle: {
        fontSize: "20px",
        lineHeight: "32px",
        fontWeight: 800
    },
    solapeHistoryCardBurnt: {
        fontSize: "20px",
        fontWeight: 800,
        backgroundColor: "#f3ec78",
        backgroundImage: "linear-gradient(100.61deg, #FF810A 0%, #DE0269 100%)",
        backgroundSize: "100%",
        WebkitBackgroundClip: "text",
        backgroundClip: "text",
        color: "transparent"
    },
    solapeHistoryCardText: {
        fontSize: "16px",
        lineHeight: "26px"
    },
    solapeBottomMetrics: {
        fontSize: "40px",
        lineHeight: "40px",
        fontWeight: 800,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    bottomMetricsContainer: {
        maxWidth: "100%",
        width: "1020px",
        margin: "24px auto",
    },
    bottomMetricsSubtitle: {
        fontSize: "18px",
        lineHeight: "40px",
        margin: 0
    },
    bottomMetricsIcon: {
        marginRight: "8px"
    }
}));

const currencyConfig = {
    style: 'currency',
    currency: 'USD',
    useGrouping: true,
};
const currencyFormat = new Intl.NumberFormat('en-US', currencyConfig);
const longCurrencyFormat = new Intl.NumberFormat('en-US', {
    ...currencyConfig,
    minimumFractionDigits: 6,
});


export default function TokenomicsPage() {
    const marketAddress = "4zffJaPyeXZ2wr4whHgP39QyTfurqZ2BEd4M5W6SEuon";
    function setMarketAddress() {
        return;
    }

    return (
        <MarketProvider
            marketAddress={marketAddress}
            setMarketAddress={setMarketAddress}
        >
            <TokenomicsInnerPage />
        </MarketProvider>
    );
}

function TokenomicsInnerPage() {
    const styles = useStyles();

    const {
        market,
        marketName,
        customMarkets,
        setCustomMarkets,
        setMarketAddress,
    } = useMarket();

    const solapePrice = useMarkPrice() || 0;
    const marketCap = solapePrice * 298_401_000;
    const burntValueMillions = ((solapePrice * 701_599_000) / 1_000_000).toFixed(1);

    const solapeSPLAddress = "GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN";

    return (

        <Grid
            container
            justifyContent="flex-start"
            alignItems="flex-start"
            className={styles.root}
        >

            <Grid item container spacing={0} className={styles.header}>
                <Grid
                    item
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="stretch"
                    spacing={1}
                >
                    <Grid item>
                        <h1 className={styles.mainTitle}>Tokenomics</h1>
                    </Grid>

                    <Hidden smDown>
                        <CoinListings />
                    </Hidden>
                </Grid>
            </Grid>

            <Box>
                <Grid item container spacing={2} alignItems="stretch">

                    <Grid item md={5} xs={12}>
                        <Box className={styles.solapeTokenCard}>
                            <Grid container className={styles.solapeTokenCardHeader}>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <p className={styles.solapeTokenCardHeaderTitle}>
                                        <img
                                            src="/icons/solape.svg"
                                            alt="Solape icon"
                                            className={styles.solapeTokenCardHeaderImage}
                                        />
                                        SOLAPE
                                    </p>
                                </Grid>
                                <Grid item xs={6}>
                                    <p className={styles.solapeTokenCardHeaderPrice}>
                                        {longCurrencyFormat.format(solapePrice)}
                                    </p>
                                </Grid>
                            </Grid>

                            <Grid container className={styles.solapeTokenCardItem} spacing={3}>
                                <Grid item md={6} xs={12}>
                                    <p className={styles.solapeTokenCardTitle}>
                                        Max Supply
                                    </p>
                                    <p className={[
                                        styles.solapeTokenCardMetric,
                                        "solapeTokenCardMetric-responsivebs"
                                    ].join(" ")}>
                                        298,401,000
                                    </p>
                                </Grid>
                                <Grid item md={6} xs={12}>
                                    <p className={styles.solapeTokenCardTitle}>
                                        Circulating Supply
                                    </p>
                                    <p className={[
                                        styles.solapeTokenCardMetric,
                                        "solapeTokenCardMetric-responsivebs"
                                    ].join(" ")}>
                                        298,401,000
                                    </p>
                                </Grid>
                                <Grid item md={12}>
                                    <p className={styles.solapeTokenCardTitle}>
                                        Market Cap &amp; FDV
                                    </p>
                                    <p className={[
                                        styles.solapeTokenCardMetric,
                                        styles.solapeFullMarketCap
                                    ].join(' ')}>
                                        {currencyFormat.format(marketCap)}
                                    </p>
                                </Grid>
                            </Grid>

                            <div className={styles.solapeTokenCardItem}>
                                <p className={styles.solapeTokenCardTitle}>
                                    SPL Contract Adress
                                </p>
                                <Box display="flex" className={styles.walletId}>
                                    <p className={styles.walletCopyId}>
                                        {solapeSPLAddress}
                                    </p>
                                    <IconButton
                                        color="primary"
                                        aria-label="copy wallet address"
                                        component="span"
                                        className={styles.walletIdCopyIcon}
                                        size="small"
                                        onClick={() =>
                                            navigator.clipboard.writeText(solapeSPLAddress)
                                        }
                                    >
                                        <FileCopy />
                                    </IconButton>
                                </Box>

                                <Button
                                    variant="contained"
                                    color="primary"
                                    className={styles.buySolapeButton}
                                    href="https://solapeswap.io/#/market/4zffJaPyeXZ2wr4whHgP39QyTfurqZ2BEd4M5W6SEuon"
                                >
                                    Buy SOLAPE
                                </Button>
                            </div>
                        </Box>
                    </Grid>

                    <Hidden mdUp>
                        <Grid
                            item
                            container
                            md={5}
                            xs={12}
                            direction="row"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={1}
                        >
                            <CoinListings />
                        </Grid>
                    </Hidden>

                    <Grid item md={4} xs={12}>
                        <Box className={styles.solapeCoinDistributionCard}>
                            <div className={styles.solapeTokenCardItemChartPadding}>
                                <img
                                    className={styles.coinDistributionChart}
                                    src="/images/tokenomics/coin-distribution-charts.svg"
                                    alt="Market distribution"
                                />
                            </div>
                            <Grid
                                container
                                spacing={3}
                                className={[
                                    styles.solapeTokenCardItem,
                                    styles.centeredMetrics
                                ].join(" ")}
                            >
                                <Grid item md={6}>
                                    <p className={[
                                        styles.solapeTokenCardTitle,
                                        styles.solapeTokenCardTitleMarket
                                    ].join(" ")}>
                                        Market
                                    </p>
                                    <p className={[
                                        styles.solapeTokenCardMetric,
                                        styles.solapeTokenCardMetricMarket
                                    ].join(" ")}>
                                        ~272.4M
                                    </p>
                                </Grid>
                                <Grid item md={6}>
                                    <p className={[
                                        styles.solapeTokenCardTitle,
                                        styles.solapeTokenCardTitleMarket
                                    ].join(" ")}>
                                        Team
                                    </p>
                                    <p className={[
                                        styles.solapeTokenCardMetric,
                                        styles.solapeTokenCardMetricMarket
                                    ].join(" ")}>
                                        ~25.9M
                                    </p>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>

                    <Grid item md={3} xs={12}>
                        <Box className={[
                            styles.solapeHistoryCard,
                            styles.solapeTokenCardItem
                        ].join(" ")}>
                            <h2 className={styles.solapeHistoryCardTitle}>
                                Some history
                            </h2>

                            <p className={styles.solapeHistoryCardText}>
                                SOLAPE initially had a supply of 1 Billion tokens. We airdropped 125k tokens each to 2,000 people who filled a form (250M total).
                            </p>

                            <p className={styles.solapeHistoryCardText}>
                                Oh, we also burned <span className={styles.solapeHistoryCardBurnt}>701,599,000 SOLAPE (70.16%).</span>
                            </p>

                            <p className={styles.solapeHistoryCardText}>
                                But don't take our word for it, check the chain&nbsp;
                                <a href="https://solscan.io/token/GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN">
                                    right here
                                </a>.
                            </p>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            <Box className={styles.bottomMetricsContainer}>
                <Grid item container spacing={2}>
                    <Grid item md={4} xs={12}>
                        <Box className={[
                            styles.solapeCoinDistributionCard,
                            styles.centeredMetrics,
                            styles.solapeTokenCardItem
                        ].join(" ")}>
                            <p className={[
                                styles.solapeTokenCardMetric,
                                styles.solapeFullMarketCap,
                                styles.solapeBottomMetrics,
                                "solapeBigNumbersBottom-responsivebs"
                            ].join(' ')}>
                                <img
                                    src="/images/tokenomics/fire.svg"
                                    alt="Fire"
                                    className={[
                                        styles.bottomMetricsIcon
                                    ].join(" ")}
                                />
                                70.16%
                            </p>
                            <p className={styles.bottomMetricsSubtitle}>
                                of initial supply (1B) burned
                            </p>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box className={[
                            styles.solapeCoinDistributionCard,
                            styles.centeredMetrics,
                            styles.solapeTokenCardItem
                        ].join(" ")}>
                            <p className={[
                                styles.solapeTokenCardMetric,
                                styles.solapeFullMarketCap,
                                styles.solapeBottomMetrics,
                                "solapeBigNumbersBottom-responsivebs"
                            ].join(' ')}>
                                <img
                                    src="/images/tokenomics/sun.svg"
                                    alt="Sun"
                                    className={[
                                        styles.bottomMetricsIcon
                                    ].join(" ")}
                                />
                                ${burntValueMillions}M
                            </p>
                            <p className={styles.bottomMetricsSubtitle}>
                                burned value (current prices)
                            </p>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box className={[
                            styles.solapeCoinDistributionCard,
                            styles.centeredMetrics,
                            styles.solapeTokenCardItem
                        ].join(" ")}>
                            <p className={[
                                styles.solapeTokenCardMetric,
                                styles.solapeFullMarketCap,
                                styles.solapeBottomMetrics,
                                "solapeBigNumbersBottom-responsivebs"
                            ].join(' ')}>
                                <img
                                    src="/images/tokenomics/gift.svg"
                                    alt="Gift"
                                    className={[
                                        styles.bottomMetricsIcon
                                    ].join(" ")}
                                />
                                $5,562.5
                            </p>
                            <p className={styles.bottomMetricsSubtitle}>
                                OG airdrop value (current)
                            </p>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Grid>
    );
}

function CoinListings() {
    const styles = useStyles();

    return (
        <>
            <span className={styles.logosSpacer}></span>
            {/* <Grid item>
                <p className={styles.exchangesListItem}>
                    <a href=""></a>
                    <img
                        src="/images/tokenomics/ftx.svg"
                        alt="FTX"
                    />
                </p>
            </Grid> */}
            <Grid item>
                <a target="_blank" rel="noopener noreferrer" href="https://www.coingecko.com/en/coins/solape-token">
                    <p className={styles.exchangesListItem}>
                        <img
                            src="/images/tokenomics/coingecko.svg"
                            alt="Coingecko"
                        />
                    </p>
                </a>
            </Grid>
            <Grid item>
                <a target="_blank" rel="noopener noreferrer" href="https://coinmarketcap.com/currencies/solapefinance/">
                    <p className={styles.exchangesListItem}>
                        <img
                            src="/images/tokenomics/coin-marketcap.svg"
                            alt="CoinMarketCap"
                        />
                    </p>
                </a>
            </Grid>
            <Grid item>
                <a target="_blank" rel="noopener noreferrer" href="https://solanabeach.io/token/GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN">
                    <p className={styles.exchangesListItem}>
                        <img
                            src="/images/tokenomics/solana-beach.svg"
                            alt="Solana Beach"
                        />
                    </p>
                </a>
            </Grid>
            <Grid item>
                <a target="_blank" rel="noopener noreferrer" href="https://solscan.io/token/GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN">
                    <p className={styles.exchangesListItem}>
                        <img
                            src="/images/tokenomics/solscan.svg"
                            alt="Solscan"
                        />
                    </p>
                </a>
            </Grid>
        </>
    );
}
