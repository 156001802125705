import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Sparklines, SparklinesLine } from "react-sparklines";
import { TokenGrid } from "../components/Swap/components/DestinationStats";
import cgTokens from "../utils/cg_map.json";
import CoinLogos from "../config/logos.json";
import "./HomePage.less";


export default function HomePage() {
  const [activeSwapNav, setActiveSwapNav] = useState<boolean>(false);
  const [navExpanded, setNavExpanded] = useState<boolean>(false);
  const [activeFaq, setActiveFaq] = useState<number>(0);
  const [tokens, setTokens] = useState<any>({
    MNGO: null,
    LIKE: null,
    SOLAPE: null,
    BOP: null,
    SOL: null,
    ETH: null,
    HXRO: null,
    LINK: null,
    UNI: null,
    RAY: null,
  });

  function getToken(token, id) {
    const cgToken = cgTokens.find(cgToken => cgToken.symbol.toUpperCase() === token);
    fetch(`https://api.coingecko.com/api/v3/coins/${id}?tickers=false&community_data=false&developer_data=false&sparkline=true`)
      .then(response => response.json())
      .then(json => {
        if (json) {
          setTokens((prevState) => ({ ...prevState, [token]: json }));
        }
      });
  }

  function expandNav(e) {
    e.preventDefault();
    setNavExpanded(true);
  }

  function collapseNav(e) {
    e.preventDefault();
    setNavExpanded(false);
  }

  function hoverSwap() {
    setActiveSwapNav(true);
  }

  function exitSwap() {
    setActiveSwapNav(false);
  }

  useEffect(() => {
    Object.keys(tokens).map(token => {
      const cgToken = cgTokens.find(cgToken => cgToken.symbol.toUpperCase() === token);
      if (cgToken) {
        getToken(token, cgToken.id);
      }
    });
  }, []);

  return (
    <>
      <div className="container-home">
        <div className={'top-nav' + (navExpanded ? ' expanded' : '')}>
        </div>

        <div className="section hero">
          <h5>
            Powered by
            <img src="/images/openbook.svg" alt="OPENBOOK" />
          </h5>
          <h1>The sharpest DEX on Solana.</h1>
          <p>Built for Apes, by Apes.</p>
          <div className="cta">
            <a href="/">DEX trading</a>
            <a href="https://solape.io" style={{ position: 'relative' }} className="swap">
              Swap
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.39179 10.285C4.78668 9.89888 5.41981 9.906 5.80592 10.3009C6.19203 10.6958 6.18492 11.3289 5.79003 11.715L3.4531 14L19 14C19.5523 14 20 14.4477 20 15C20 15.5523 19.5523 16 19 16L3.4531 16L5.79003 18.285C6.18492 18.6711 6.19203 19.3042 5.80592 19.6991C5.41981 20.094 4.78668 20.1011 4.39179 19.715L0.300882 15.715C0.108465 15.5269 5.64153e-08 15.2691 5.96244e-08 15C6.28335e-08 14.7309 0.108465 14.4731 0.300882 14.285L4.39179 10.285ZM15.6082 0.284994C15.2133 -0.101118 14.5802 -0.0940037 14.1941 0.300882C13.808 0.69577 13.8151 1.3289 14.21 1.71501L16.5469 4L1 4C0.447716 4 1.85459e-07 4.44771 1.78873e-07 5C1.72287e-07 5.55228 0.447716 6 1 6L16.5469 6L14.21 8.28499C13.8151 8.6711 13.808 9.30423 14.1941 9.69912C14.5802 10.094 15.2133 10.1011 15.6082 9.71501L19.6991 5.71501C19.8915 5.52687 20 5.26911 20 5C20 4.73089 19.8915 4.47313 19.6991 4.28499L15.6082 0.284994Z" fill="white" />
              </svg>
            </a>
          </div>
        </div>

        <div className="section tokens">
          <div className="track cards" style={{ width: 265 * tokens.length }}>
            {Object.keys(tokens).map(token => (
              <div className="token card">
                <div className="inner">
                  <div className="ticker">
                    <img src={CoinLogos[token]} />
                    {token}
                  </div>
                  {tokens[token] ?
                    <>
                      <strong>
                        {tokens[token].market_data?.current_price?.["usd"] ?
                          new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 4 }).format(tokens[token].market_data.current_price["usd"])
                          : "-"}
                      </strong>
                      {tokens[token].market_data?.price_change_percentage_24h &&
                        <sub className={tokens[token].market_data?.price_change_percentage_24h >= 0 ? "gain" : "loss"}>
                          <svg width="14" height="11" viewBox="0 0 14 11" fill="none">
                            <path d="M6.06218 0.499999C6.44708 -0.166667 7.40933 -0.166667 7.79423 0.5L12.9904 9.5C13.3753 10.1667 12.8942 11 12.1244 11H1.73205C0.96225 11 0.481125 10.1667 0.866025 9.5L6.06218 0.499999Z" fill="#29F592" />
                          </svg>
                          {tokens[token].market_data.price_change_percentage_24h.toFixed(2)}%
                        </sub>
                      }
                    </>
                    : <p><em>Loading...</em></p>}
                </div>
                {tokens[token]?.market_data?.sparkline_7d &&
                  <Sparklines data={tokens[token].market_data.sparkline_7d.price} height={130} width={248}>
                    <defs>
                      <LinearGradientFill />
                    </defs>
                    <SparklinesLine color="#F37B21" style={{ fillOpacity: "1", fill: "url(#gradient)", strokeWidth: "2" }} />
                  </Sparklines>
                }
              </div>
            ))}
          </div>
        </div>

        <div className="section teaser dex">
          <div className="inner">
            <h2>An unmatched DEX trading experience.</h2>
            <p>
              Regardless of your trading level, Solape can accommodate you for a seamless, integrated experience trading in the Solana ecosystem.
            </p>
            <a href="/#/">
              Explore the DEX
              <svg width="28" height="16" viewBox="0 0 28 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2608 15.6009C18.7768 16.1263 19.621 16.1339 20.1464 15.6179L26.9342 8.95126C27.1895 8.70056 27.3333 8.35779 27.3333 8C27.3333 7.64221 27.1895 7.29944 26.9342 7.04873L20.1464 0.382069C19.621 -0.133919 18.7768 -0.126314 18.2608 0.399055C17.7448 0.924424 17.7524 1.76861 18.2778 2.2846L22.7395 6.66667L1.99996 6.66667C1.26358 6.66667 0.666626 7.26362 0.666626 8C0.666626 8.73638 1.26358 9.33333 1.99996 9.33333L22.7395 9.33333L18.2778 13.7154C17.7524 14.2314 17.7448 15.0756 18.2608 15.6009Z" fill="#FFAB5C" />
              </svg>
            </a>
          </div>
        </div>

        <div className="section teaser assets">
          <div className="inner">
            <h2>A rich offering of tradeable assets.</h2>
            <p>
              We have one of the most diverse and rapidly growing offering of tradeable assets. We move fast but also try to maintain quality.
            </p>
            <a href="/#/markets">
              See trading pairs
              <svg width="28" height="16" viewBox="0 0 28 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2608 15.6009C18.7768 16.1263 19.621 16.1339 20.1464 15.6179L26.9342 8.95126C27.1895 8.70056 27.3333 8.35779 27.3333 8C27.3333 7.64221 27.1895 7.29944 26.9342 7.04873L20.1464 0.382069C19.621 -0.133919 18.7768 -0.126314 18.2608 0.399055C17.7448 0.924424 17.7524 1.76861 18.2778 2.2846L22.7395 6.66667L1.99996 6.66667C1.26358 6.66667 0.666626 7.26362 0.666626 8C0.666626 8.73638 1.26358 9.33333 1.99996 9.33333L22.7395 9.33333L18.2778 13.7154C17.7524 14.2314 17.7448 15.0756 18.2608 15.6009Z" fill="#FFAB5C" />
              </svg>
            </a>
          </div>
        </div>

        <div className="section teaser swap">
          <div className="inner">
            <h2>A fully-fledged swap app.</h2>
            <p>
              A simple-to-use swap functionality, integrated with asset information, charts, and slippage controls.
            </p>
            <a href="https://www.solape.io/" style={{ position: 'relative', cursor: 'pointer' }}>
              Try the swap
              <svg width="28" height="16" viewBox="0 0 28 16" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.2608 15.6009C18.7768 16.1263 19.621 16.1339 20.1464 15.6179L26.9342 8.95126C27.1895 8.70056 27.3333 8.35779 27.3333 8C27.3333 7.64221 27.1895 7.29944 26.9342 7.04873L20.1464 0.382069C19.621 -0.133919 18.7768 -0.126314 18.2608 0.399055C17.7448 0.924424 17.7524 1.76861 18.2778 2.2846L22.7395 6.66667L1.99996 6.66667C1.26358 6.66667 0.666626 7.26362 0.666626 8C0.666626 8.73638 1.26358 9.33333 1.99996 9.33333L22.7395 9.33333L18.2778 13.7154C17.7524 14.2314 17.7448 15.0756 18.2608 15.6009Z" fill="#FFAB5C" />
              </svg>
            </a>
          </div>
        </div>

        <div className="section solape">
          <div className="solape-header">
            <div className="inner">
              <h2>$SOLAPE Token</h2>
              <p className="address">
              GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <circle cx="12" cy="12" r="12" fill="#FF810A" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33331 10.6667L5.33331 16.0001C5.33331 17.4728 6.52722 18.6667 7.99998 18.6667L13.3333 18.6667C14.8061 18.6667 16 17.4728 16 16.0001C17.4727 16.0001 18.6666 14.8062 18.6666 13.3334L18.6666 11.0001L18.6666 8.00008C18.6666 6.52732 17.4727 5.33342 16 5.33342L10.6666 5.33342C9.19389 5.33342 7.99998 6.52732 7.99998 8.00008C6.52722 8.00008 5.33331 9.19399 5.33331 10.6667ZM9.33331 8.00008L13.3333 8.00008C14.8061 8.00008 16 9.19399 16 10.6667L16 14.6667C16.7364 14.6667 17.3333 14.0698 17.3333 13.3334L17.3333 11.0001L17.3333 8.00008C17.3333 7.2637 16.7364 6.66675 16 6.66675L10.6666 6.66675C9.93027 6.66675 9.33331 7.2637 9.33331 8.00008ZM7.99998 17.3334C7.2636 17.3334 6.66665 16.7365 6.66665 16.0001L6.66665 10.6667C6.66665 9.93037 7.2636 9.33341 7.99998 9.33341L13.3333 9.33341C14.0697 9.33341 14.6666 9.93037 14.6666 10.6667L14.6666 16.0001C14.6666 16.7365 14.0697 17.3334 13.3333 17.3334L7.99998 17.3334Z" fill="#FFFAF5" />
                </svg>
              </p>
              <div className="ctas">
                <a href="https://solanabeach.io/token/GHvFFSZ9BctWsEc5nujR1MTmmJWY7tgQz2AXE6WVFtGN" target="_blank" className="beach">Solana Beach</a>
                <a href="https://www.coingecko.com/en/coins/solape-token" target="_blank" className="gecko">CoinGecko</a>
                <a href="https://coinmarketcap.com/currencies/solapefinance/" target="_blank" className="cmc">CoinMarketCap</a>
              </div>
            </div>
          </div>
          <div className="solape-stats">
            <TokenGrid token="solape">
              <a href="/#/market/8rUvvjhtyjiJYTVhNn8usWDAQn1RHwt2adChzk7ANeT4" className="cta">
                Buy SOLAPE
                <img src="/icons/solape.svg" />
              </a>
            </TokenGrid>
          </div>
        </div>

        <div className="section roadmap">
          <h3>Roadmap</h3>
          <div className="cards">
            <div className="card current">
              <h4>Q1 2023</h4>
              <ul>
                <li>OpenBook DEX Migration</li>
                <li>Product Development</li>
                <li>Infrastructure Migration</li>
                <li>Surviving Bear Market</li>
              </ul>
            </div>
            <div className="card">
              <h4>Q2 2023</h4>
              <ul>
                <li>Swap Sweepstakes Launch</li>
                <li>App Optimizaitons</li>
                <li>DEX Referral Suite Launch</li>
                <li>Community Initiatves</li>
              </ul>
            </div>
            <div className="card">
              <h4>Q3 2023</h4>
              <ul>
                <li>NFT Access Card v2 Launch</li>
                <li>Solape dApp v3 Launch</li>
                <li>Charting API Development</li>
                <li>ApeXLev v2 Development</li>
              </ul>
            </div>
            <div className="card">
              <h4>Q4 2023</h4>
              <ul>
                <li>redacted NFT Collection Launch</li>
                <li>Staking Contract Launch</li>
                <li>Ape Arcade v1 Launch</li>
                <li>ApeXLev v2 Launch</li>
              </ul>
            </div>
          </div>
          <p>
            The Solape team reserves the right to adjust the roadmap based on the changing market conditions and the overal industry landscape.
          </p>
        </div>

        <div className="section services">
          <h3>Services</h3>
          <div className="cards">
            <div className="card">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8565 5.46924C11.2785 7.04721 11.2785 9.60561 12.8565 11.1836L17.1426 15.4693C18.7207 17.0473 21.2793 17.0473 22.8574 15.4693L27.1435 11.1836C28.7215 9.60561 28.7215 7.04721 27.1435 5.46924L22.8574 1.18348C21.2793 -0.394493 18.7207 -0.394493 17.1426 1.18348L12.8565 5.46924ZM15.7139 8.32641L17.1426 6.89782L18.5713 5.46924L20 4.04065L21.4287 5.46924L22.8574 6.89782L24.2861 8.32641L22.8574 9.75499L21.4287 11.1836L20 12.6122L18.5713 11.1836L17.1426 9.75499L15.7139 8.32641ZM1.18357 17.1428C-0.394522 18.7208 -0.394522 21.2792 1.18357 22.8572L5.46964 27.1429C7.04773 28.7209 9.60632 28.7209 11.1844 27.1429L15.4705 22.8572C17.0486 21.2792 17.0486 18.7208 15.4705 17.1428L11.1844 12.8571C9.60632 11.2791 7.04773 11.2791 5.46964 12.8571L1.18357 17.1428ZM4.04095 20L5.46964 18.5714L6.89833 17.1428L8.32702 15.7142L9.75572 17.1428L11.1844 18.5714L12.6131 20L11.1844 21.4286L9.75572 22.8572L8.32702 24.2858L6.89833 22.8572L5.46964 21.4286L4.04095 20ZM12.8565 34.5308C11.2785 32.9528 11.2785 30.3944 12.8565 28.8164L17.1426 24.5307C18.7207 22.9527 21.2793 22.9527 22.8574 24.5307L27.1435 28.8164C28.7215 30.3944 28.7215 32.9528 27.1435 34.5308L22.8574 38.8165C21.2793 40.3945 18.7207 40.3945 17.1426 38.8165L12.8565 34.5308ZM17.1426 30.245L15.7139 31.6736L17.1426 33.1022L18.5713 34.5308L20 35.9594L21.4287 34.5308L22.8574 33.1022L24.2861 31.6736L22.8574 30.245L21.4287 28.8164L20 27.3878L18.5713 28.8164L17.1426 30.245ZM28.8156 12.8571L24.5295 17.1428C22.9514 18.7208 22.9514 21.2792 24.5295 22.8572L28.8156 27.1429C30.3937 28.7209 32.9523 28.7209 34.5304 27.1429L38.8164 22.8572C40.3945 21.2792 40.3945 18.7208 38.8164 17.1428L34.5304 12.8571C32.9523 11.2791 30.3937 11.2791 28.8156 12.8571ZM27.3869 20L28.8156 18.5714L30.2443 17.1428L31.673 15.7142L33.1017 17.1428L34.5304 18.5714L35.9591 20L34.5304 21.4286L33.1017 22.8572L31.673 24.2858L30.2443 22.8572L28.8156 21.4286L27.3869 20Z" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient id="paint0_linear" x1="0" y1="0" x2="45.8829" y2="8.59484" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF810A" />
                    <stop offset="1" stop-color="#FFAB5C" />
                  </linearGradient>
                </defs>
              </svg>
              <h4>API Access</h4>
              <p>Looking to get access to our custom Solape API? Need a market feed to implement into your up-and-coming DAPP?</p>
              <a href="https://discord.gg/solape" className="cta" target="_blank">Request access</a>
            </div>
            <div className="card">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M34 0H6C2.68629 0 0 2.68629 0 6V34C0 37.3137 2.68629 40 6 40H34C37.3137 40 40 37.3137 40 34V6C40 2.68629 37.3137 0 34 0ZM4 6C4 4.89543 4.89543 4 6 4H33.6729L22.3199 23.073L15.4708 15.6443C15.0303 15.1665 14.3851 14.9326 13.7408 15.0169C13.0964 15.1013 12.5332 15.4934 12.2306 16.0685L4 31.7066V6ZM6.2605 36H34C35.1046 36 36 35.1046 36 34V7.91088L24.3857 27.423C24.0627 27.9655 23.5026 28.3237 22.8746 28.3892C22.2467 28.4547 21.6246 28.2199 21.1967 27.7557L14.4506 20.4388L6.2605 36Z" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient id="paint0_linear" x1="0" y1="0" x2="45.8829" y2="8.59484" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF810A" />
                    <stop offset="1" stop-color="#FFAB5C" />
                  </linearGradient>
                </defs>
              </svg>
              <h4>Charting</h4>
              <p>Solape offers extensive charting tools on-site, as well as an external service for integration purposes.</p>
              <a href="https://discord.gg/solape" className="cta" target="_blank">Request access</a>
            </div>
            <div className="card">
              <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.0001 0C21.4423 0 22.7729 0.776417 23.4825 2.03205L28.3532 10.6511L36.7681 12.2973C38.234 12.5841 39.418 13.6638 39.8384 15.0972C40.2588 16.5306 39.8454 18.0788 38.7666 19.1119L32.3664 25.2408L33.847 35.4245C34.0618 36.9018 33.4361 38.3757 32.2242 39.2473C31.0123 40.119 29.4158 40.2432 28.0837 39.5696L20.0001 35.4823L11.9164 39.5696C10.5843 40.2432 8.98784 40.119 7.77594 39.2473C6.56404 38.3757 5.93835 36.9018 6.15313 35.4245L7.63373 25.2408L1.23352 19.1119C0.15466 18.0788 -0.258657 16.5306 0.161716 15.0972C0.582088 13.6638 1.7661 12.5841 3.23206 12.2973L11.6469 10.6511L16.5176 2.03205C17.2272 0.776417 18.5578 0 20.0001 0ZM14.223 14.2229L4.00005 16.2229L11.8886 23.7771L10.1115 36L20.0001 31L29.8886 36L28.1115 23.7771L36.0001 16.2229L25.7771 14.2229L20.0001 4L14.223 14.2229Z" fill="url(#paint0_linear)" />
                <defs>
                  <linearGradient id="paint0_linear" x1="-6.10352e-05" y1="0" x2="45.883" y2="8.59493" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FF810A" />
                    <stop offset="1" stop-color="#FFAB5C" />
                  </linearGradient>
                </defs>
              </svg>
              <h4>Listing</h4>
              <p>Want us to list your project? Adding custom markets is allowed by default, but verified listings put you in a more prominent spot.</p>
              <a href="https://dex.solape.io/#/token-listing" className="cta" target="_blank">Submit request</a>
            </div>
          </div>
          <p>Have a custom request in mind? Reach out <a href="mailto:info@solape.io">here</a>.</p>
        </div>

        <div className="section faq">
          <h3>Frequently Asked Questions</h3>
          {/*<p>For more information, please check our <a href="#">Help page</a>.</p>*/}
          <div className="faqs">
            <div className={"question" + (activeFaq === 0 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(0)}>
                What is SolAPE and what makes it different?
              </div>
              <div className="answer">
                SolAPE is an ecosystem driven by user-experience. We pride ourselves in offering a trading solution that is smooth and seamless while also building out interactive ways for our community to embrace cryptocurrency and DeFi.
              </div>
            </div>
            <div className={"question" + (activeFaq === 1 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(1)}>
                I’m new to DeFi, how can I get started?
              </div>
              <div className="answer">
                Here’s a great guide on the fundamentals of <a href="https://blog.coinbase.com/a-beginners-guide-to-decentralized-finance-defi-574c68ff43c4" target="_blank">Decentralized Finance</a>. In order to begin trading on SolAPE Swap, you’ll first need a small amount of SOL and USDC in your SPL wallet. This can be acquired from a centralized exchange such as FTX.
              </div>
            </div>
            <div className={"question" + (activeFaq === 2 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(2)}>
                Why build on Solana?
              </div>
              <div className="answer">
                Solana stands out in DeFi with lightning-fast speeds of up to 65,000 transactions per second, 400ms block times, and less than $0.01 average transaction fees. SolAPE are firm believers in the Solana innovation and believe it will power the future DeFi.
              </div>
            </div>
            <div className={"question" + (activeFaq === 3 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(3)}>
                Can I use SolAPE on my phone?
              </div>
              <div className="answer">
                You can access SolAPE from your mobile browser by connecting your wallet. We plan to roll out a more smartphone-friendly version later in the year.
              </div>
            </div>
            <div className={"question" + (activeFaq === 4 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(4)}>
                How can I get in touch?
              </div>
              <div className="answer">
                We are a community focused group and offer support through both our discord server and twitter account. Alternatively you can reach out to us at info@solape.io
              </div>
            </div>
            <div className={"question" + (activeFaq === 5 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(5)}>
                What does "Your SOL balance is low" mean?
              </div>
              <div className="answer">
                SOL is required to pay network (gas) fees. Fees are very low on Solana and you may be able to make a number of transactions with a low balance, however we recommend keeping at least 0.1 SOL in your wallet for gas.
              </div>
            </div>
            <div className={"question" + (activeFaq === 6 ? ' active' : '')}>
              <div className="header" onClick={() => setActiveFaq(6)}>
                Why did my transaction fail?
              </div>
              <div className="answer">
                As users get more acquainted with DeFi usage, there may be a number of learning-curves or potential issues that can arise. Here are some generic troubleshooting solutions for failed transactions.
                <br /><br />
                Insufficient SOL: SOL is required to pay network fees (gas), we recommend keeping at least 0.1 SOL in your wallet to ensure smooth transactions.
                <br /><br />
                Incorrect Account: In some cases, users may have multiple accounts created for a single token in their wallets. Ensure that you have the correct account selected in the DEX when making trades.
                <br /><br />
                Approving Transactions: If you see the “Making Transaction” notification in the lower left-hand corner of your screen, you will need to approve the transaction in your SPL wallet.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const LinearGradientFill = () => {
  return (
    <linearGradient id="gradient" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop offset="0%" stopColor="rgb(255, 129, 10, 0.2)" stopOpacity="1" />
      <stop offset="100%" stopColor="255, 171, 92, 0" stopOpacity="0" />
    </linearGradient>
  );
};
