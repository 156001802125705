import React from "react";
import { Table, Tooltip } from 'antd';
import { Grid, makeStyles } from "@material-ui/core";
import { InfoCircleOutlined } from '@ant-design/icons';
import pastAirdrops from "../config/past-airdrops.json";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: "0 auto",
    maxWidth: "100%",
    width: "1200px",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    padding: "0 16px"
  },
  mainTitle: {
    marginTop: "40px",
    marginBottom: "16px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "40px",
    lineHeight: "48px"
  },
  subTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "40px",
    color: "rgba(255, 255, 255, 0.75)",
    maxWidth: "100%",
    width: "870px"
  },
  airdropMetricsLeft: {
    padding: "16px 32px",
    background: "linear-gradient(100.61deg, #FF810A 0%, #E06C00 100%)",
    textAlign: "left",
    borderRadius: "8px"
  },
  airdropMetricsLeftTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(255, 255, 255, 0.75)"
  },
  airdropMetricsLeftPrice: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    color: "#FFFFFF",
    margin: 0
  },
  airdropMetricsRight: {
    padding: "16px 32px",
    background: "transparent",
    textAlign: "left"
  },
  airdropMetricsRightTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(255, 255, 255, 0.75)"
  },
  airdropMetricsRightPrice: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "24px",
    lineHeight: "32px",
    margin: 0,
    backgroundColor: "#f3ec78",
    backgroundImage: "linear-gradient(100.61deg, #FF810A 0%, #FFAB5C 100%)",
    backgroundSize: "100%",
    WebkitBackgroundClip: "text",
    backgroundClip: "text",
    color: "transparent"
  },
  airdropTable: {

  }
}));

let getColumnTooltip = function (text, tooltipType) {
  let tooltipText;

  if ("requirement" === tooltipType) {
    tooltipText = "The amount of SOLAPE needed in your wallet to receive the airdrop."
  } else if ("airdropAmount" === tooltipType) {
    tooltipText = "The amount of token each wallet received if they were part of this airdrop."
  } else if ("originValuation" === tooltipType) {
    tooltipText = "The value of the airdropped token on the date of the airdrop. "
  } else if ("athValuation" === tooltipType) {
    tooltipText = "This value of the airdrop when the coin did hit its all time high valuation."
  } else {
    return text;
  }

  return (
    <>
      {text}&nbsp;
      <Tooltip placement="top" title={tooltipText}>
        <InfoCircleOutlined />
      </Tooltip>
    </>
  )
};

const defaultSortOrder: "descend" | "ascend" | null | undefined = "descend";

const columns = [
  {
    title: 'Asset',
    dataIndex: 'asset',
    key: 'asset'
  },
  {
    title: 'Airdrop date',
    dataIndex: 'timestamp',
    key: 'timestamp',
  },
  {
    title: getColumnTooltip('Requirement', 'requirement'),
    dataIndex: 'requirement',
    key: 'requirement',
  },
  {
    title: getColumnTooltip('Airdrop amount', 'airdropAmount'),
    key: 'airdropAmount',
    dataIndex: 'airdropAmount',
  },
  // {
  //   title: getColumnTooltip('Original valuation', 'originValuation'),
  //   key: 'originValuation',
  //   dataIndex: 'originValuation',
  //   render: text => currencyFormat.format(text),
  // },
  {
    title: getColumnTooltip('ATH valuation', 'athValuation'),
    key: 'athValuation',
    dataIndex: 'athValuation',
    render: text => currencyFormat.format(text),
    sorter: (a, b) => a.athValuation - b.athValuation,
    defaultSortOrder,
  },
];

const currencyConfig = {
  style: 'currency',
  currency: 'USD',
  useGrouping: true
};
const currencyFormat = new Intl.NumberFormat('en-US', currencyConfig)


export default function PastAirdropsPage() {
  const styles = useStyles();

  //@ts-ignore
  const totalAirdop = pastAirdrops.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.athValuation;
  }, 0) * 400;

  //@ts-ignore
  const totalAirdopPerWallet: number = pastAirdrops.reduce((previousValue, currentValue) => {
    return previousValue + currentValue.athValuation;
  }, 0);

  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="flex-start"
      className={styles.root}
    >
      <h1 className={styles.mainTitle}>Past airdrops overview</h1>
      <p className={styles.subTitle}>Solape token holders have been receiving airdrops since our inception. Below is a list of all airdrops received and their original and top valuations.</p>

      <div className="solape__past-airdrops__summary">
        <div className={styles.airdropMetricsLeft}>
          <h2 className={styles.airdropMetricsLeftTitle}>Total airdropped*</h2>
          <p className={styles.airdropMetricsLeftPrice}>
            {currencyFormat.format(totalAirdop)}
          </p>
        </div>
        <div className={styles.airdropMetricsRight}>
          <h2 className={styles.airdropMetricsRightTitle}>Airdropped per holder**</h2>
          <p className={styles.airdropMetricsRightPrice}>
            {currencyFormat.format(totalAirdopPerWallet)}
          </p>
        </div>
      </div>

      <div className="solape__airdrop-table">
        {pastAirdrops &&
          <Table
            columns={columns}
            dataSource={pastAirdrops}
            pagination={{ pageSize: 100, position: [] }}
          />
        }
      </div>

      <div className="solape__past-airdrops__asterix-text">
        <p><span>*</span> Amount calculated as a sum of all ATH valuations of all airdrops distributed to SOLAPE holders thus far.</p>
        <p><span>**</span> Calculation based on  ATH values and an average of 400 holders airdropped. This average will change over time.</p>
      </div>

      <div className="solape__past-airdrops__cta">
        <p>
          Want future airdrops ?
        </p>
        <a href="/#/market/4zffJaPyeXZ2wr4whHgP39QyTfurqZ2BEd4M5W6SEuon">Buy SOLAPE</a>
      </div>
    </Grid>
  );
}

