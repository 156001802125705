import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Col, Popover, Row, Select, Typography } from 'antd';
import styled from 'styled-components';
import Orderbook from '../components/Orderbook';
import tuple from 'immutable-tuple';
import UserInfoTable from '../components/UserInfoTable';
import StandaloneBalancesDisplay from '../components/StandaloneBalancesDisplay';
import AssetInformation from '../components/AssetInformation';
import LoadingTradePageTile from '../components/LoadingTradePageTile';
import {
  getMarketInfos,
  getTradePageUrl,
  MarketProvider,
  useMarket as uM,
  useMarketsList,
  useMarkPrice,
  useMarket,
  useMarkVolume,
  useOpenOrders,
} from '../utils/markets';
import TradeForm from '../components/TradeForm';
import DeprecatedMarketsInstructions from '../components/DeprecatedMarketsInstructions';
import {
  DeleteOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import CustomMarketDialog from '../components/CustomMarketDialog';
import { notify } from '../utils/notifications';
import { useHistory, useParams } from 'react-router-dom';
import CoinLogos from '../config/logos.json';
import { ReactComponent as StarIcon } from '../assets/img/StarIcon.svg';
import { ReactComponent as StarIconFav } from '../assets/img/StarIconFavorite.svg';
import { useLocalStorageState } from '../utils/utils';
import {
  addOrRemoveFavorite,
  isFavoriteMarkets,
} from '../utils/favoriteMarkets';
import { getCache } from '../utils/fetch-loop';
import { useWallet } from '@solana/wallet-adapter-react';
import NonConnectedTradePageTile from '../components/NotConnectedTradePageTile';

const { Option, OptGroup } = Select;

const Wrapper = styled.div`
  height: 100%;
  min-height: 399px;
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
  .borderNone .ant-select-selector {
    border: none !important;
  }
  max-width: 2560px;
  margin: 0 auto;
`;

const MarketOptionWrapper = styled(Option)`
  background: green !important;

  .ant-select-item-option-content {
    display: 'flex';
    align-items: 'center';
  }
`;

export default function TradePage() {
  const { marketAddress } = useParams();

  useEffect(() => {
    if (marketAddress) {
      localStorage.setItem('marketAddress', JSON.stringify(marketAddress));
    }
  }, [marketAddress]);
  const history = useHistory();
  function setMarketAddress(address) {
    history.push(getTradePageUrl(address));
  }

  const OpenOrdersStoreUpdater = () => {
    // console.log('Open order store updater');
    useOpenOrders();
    return null;
  };

  return (
    <MarketProvider
      marketAddress={marketAddress}
      setMarketAddress={setMarketAddress}
    >
      <OpenOrdersStoreUpdater />
      <TradePageInner />
    </MarketProvider>
  );
}

function TradePageInner() {
  const {
    market,
    marketName,
    customMarkets,
    setCustomMarkets,
    setMarketAddress,
  } = useMarket();
  const [favoriteMarkets, setFavoriteMarkets] = useLocalStorageState(
    'favoriteMarkets3',
    [],
  );
  const markets = useMarketsList();
  const [handleDeprecated, setHandleDeprecated] = useState(false);
  const [addMarketVisible, setAddMarketVisible] = useState(false);
  // const deprecatedMarkets = useUnmigratedDeprecatedMarkets();
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    document.title = marketName ? `${marketName} — Solape` : 'Solape';
  }, [marketName]);

  const changeOrderRef =
    useRef<({ size, price }: { size?: number; price?: number }) => void>();

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const width = dimensions?.width;
  const componentProps = {
    onChangeOrderRef: (ref) => (changeOrderRef.current = ref),
    onPrice: useCallback(
      (price) => changeOrderRef.current && changeOrderRef.current({ price }),
      [],
    ),
    onSize: useCallback(
      (size) => changeOrderRef.current && changeOrderRef.current({ size }),
      [],
    ),
  };
  const component = (() => {
    if (handleDeprecated) {
      return (
        <DeprecatedMarketsPage
          switchToLiveMarkets={() => setHandleDeprecated(false)}
        />
      );
    } else if (width < 1000) {
      return <RenderSmaller {...componentProps} />;
    } else if (width < 1450) {
      return <RenderSmall {...componentProps} />;
    } else if (width < 1920) {
      return <RenderNormal {...componentProps} />;
    } else {
      return <RenderLarge {...componentProps} />;
    }
  })();

  const onAddCustomMarket = (customMarket) => {
    const marketInfo = getMarketInfos(customMarkets).some(
      (m) => m.address.toBase58() === customMarket.address,
    );
    if (marketInfo) {
      notify({
        message: `A market with the given ID already exists`,
        type: 'error',
      });
      return;
    }
    const newCustomMarkets = [...customMarkets, customMarket];
    setCustomMarkets(newCustomMarkets);
    setMarketAddress(customMarket.address);
  };

  const onDeleteCustomMarket = (address) => {
    const newCustomMarkets = customMarkets.filter((m) => m.address !== address);
    setCustomMarkets(newCustomMarkets);
  };

  // const lastDay = useLastDay();

  const markVolume = useMarkVolume();

  const markGain = useMarkVolume();

  const gainColor = (() => {
    if (markGain[0] && markGain[0][0]) {
      if (markGain[0][0].price_change_24h > 0) {
        return '#0AD171';
      } else {
        return 'rgb(253, 73, 157)';
      }
    }
  })();

  const gainSymbol = (() => {
    if (markGain[0] && markGain[0][0]) {
      if (markGain[0][0].price_change_24h > 0) {
        return '+';
      } else {
        return '';
      }
    }
  })();

  const priceSymbol = (() => {
    if (marketName) {
      if (marketName.includes('USD')) {
        return 'USD';
      }
      if (marketName.includes('/SOL')) {
        return 'SOL';
      }
    }
    return 'USD';
  })();

  return (
    <>
      <CustomMarketDialog
        visible={addMarketVisible}
        onClose={() => setAddMarketVisible(false)}
        onAddCustomMarket={onAddCustomMarket}
      />
      <Wrapper>
        <Row
          align="middle"
          style={{ paddingLeft: 5, paddingRight: 5, marginBottom: 5 }}
          gutter={16}
        >
          <Col style={{ paddingRight: 0 }}>
            {market &&
              (isFavoriteMarkets(market.address.toBase58(), favoriteMarkets) ? (
                <StarIconFav
                  style={{
                    marginLeft: 8,
                    marginRight: 8,
                    marginTop: 8,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    addOrRemoveFavorite(
                      market.address.toBase58(),
                      favoriteMarkets,
                      setFavoriteMarkets,
                    )
                  }
                />
              ) : (
                <StarIcon
                  style={{
                    marginLeft: 8,
                    marginRight: 8,
                    marginTop: 8,
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    addOrRemoveFavorite(
                      market.address.toBase58(),
                      favoriteMarkets,
                      setFavoriteMarkets,
                    )
                  }
                />
              ))}
          </Col>
          <Col style={{ paddingLeft: 0 }}>
            <MarketSelector
              markets={markets}
              favoriteMarkets={favoriteMarkets}
              setHandleDeprecated={setHandleDeprecated}
              placeholder={'Select market'}
              customMarkets={customMarkets}
              onDeleteCustomMarket={onDeleteCustomMarket}
            />
          </Col>

          <Col>
            <PlusCircleOutlined
              style={{ color: '#FFFAF5' }}
              onClick={() => setAddMarketVisible(true)}
              className="solape-addmarket"
            />
          </Col>

          <Col style={{ paddingLeft: 12 }}>
            <h6 style={{ fontSize: 11, marginBottom: 1, color: '#FFCB99' }}>
              Market price
            </h6>
            <strong style={{ fontSize: 18, color: '#FFFAF5' }}>
              {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: priceSymbol,
                minimumFractionDigits: 4,
              }).format(useMarkPrice() || 0)}
            </strong>
          </Col>
          <Col style={{ paddingLeft: 8 }}>
            <h6 style={{ fontSize: 11, marginBottom: 1, color: '#FFCB99' }}>
              24h Change
            </h6>
            <strong style={{ fontSize: 18, color: gainColor }}>
              {gainSymbol}
              {markVolume[0] && markVolume[0][0]
                ? new Intl.NumberFormat('en-US', {
                    style: 'percent',
                    minimumFractionDigits: 2,
                  }).format(markVolume[0][0].price_change_24h)
                : '-'}
            </strong>
          </Col>
          <Col style={{ paddingLeft: 8 }}>
            <h6 style={{ fontSize: 11, marginBottom: 1, color: '#FFCB99' }}>
              24h Volume
            </h6>
            <strong style={{ fontSize: 18, color: '#FFFAF5' }}>
              {markVolume[0] && markVolume[0][0]
                ? new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                  }).format(markVolume[0][0].volume_24h || 0)
                : '-'}
            </strong>
          </Col>
          <Row style={{ width: '545', marginLeft: 'auto', alignItems: 'center', position: 'relative', maxWidth: '100%' }}>
            <div className="section hero">
              <h5>
            Powered by
            <img src="/images/helius.svg" alt="HELIUS" />
            </h5>
            </div>
          </Row>
          {/*<DailyGainers parentWidth={width} />

          {/* {deprecatedMarkets && deprecatedMarkets.length > 0 && (
            <React.Fragment>
              <Col>
                <Typography>
                  You have unsettled funds on old markets! Please go through
                  them to claim your funds.
                </Typography>
              </Col>
              <Col>
                <Button onClick={() => setHandleDeprecated(!handleDeprecated)}>
                  {handleDeprecated ? 'View new markets' : 'Handle old markets'}
                </Button>
              </Col>
            </React.Fragment>
          )} */}
        </Row>
        {component}
        {/*<RenderBottom onChangeOrderRef={onChangeOrderRef} onPrice={onPrice} onSize={onSize} />*/}
      </Wrapper>
    </>
  );
}

function MarketSelector({
  markets,
  favoriteMarkets,
  placeholder,
  setHandleDeprecated,
  customMarkets,
  onDeleteCustomMarket,
}) {
  const { market, setMarketAddress } = useMarket();

  const onSetMarketAddress = (marketAddress) => {
    setHandleDeprecated(false);
    setMarketAddress(marketAddress);
  };

  const extractBase = (a) => a.split('/')[0];
  const extractQuote = (a) => a.split('/')[1];

  const selectedMarket = getMarketInfos(customMarkets)
    .find(
      (proposedMarket) =>
        market?.address && proposedMarket.address.equals(market.address),
    )
    ?.address?.toBase58();

  const marketName = markets.find((m) => m.address == selectedMarket)?.name;

  return (
    <div style={{ position: 'relative' }}>
      {marketName && (
        <div className="market-logos">
          {marketName
            .split('/')
            .map(
              (market) =>
                CoinLogos[market] && (
                  <img src={CoinLogos[market]} key={market} />
                ),
            )}
        </div>
      )}

      <Select
        showSearch
        size={'large'}
        dropdownMatchSelectWidth={320}
        style={{ width: 200 }}
        placeholder={placeholder || 'Select a market'}
        optionFilterProp="name"
        onSelect={onSetMarketAddress}
        listHeight={400}
        value={selectedMarket}
        getPopupContainer={(trigger) => trigger.parentElement}
        filterOption={(input, option) =>
          option?.name?.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {customMarkets.length > 0 && (
          <OptGroup label="Custom">
            {customMarkets.map(({ address, name }, i) => (
              <Option
                value={address}
                key={address}
                name={name}
                style={{
                  padding: '10px',
                  // @ts-ignore
                  backgroundColor: i % 2 === 0 ? 'rgb(39, 44, 61)' : null,
                }}
              >
                <Row>
                  <Col flex="auto">{name}</Col>
                  {selectedMarket !== address && (
                    <Col>
                      <DeleteOutlined
                        onClick={(e) => {
                          e.stopPropagation();
                          e.nativeEvent.stopImmediatePropagation();
                          onDeleteCustomMarket && onDeleteCustomMarket(address);
                        }}
                      />
                    </Col>
                  )}
                </Row>
              </Option>
            ))}
          </OptGroup>
        )}
        <OptGroup label="Markets">
          {markets
            .sort((a, b) =>
              extractQuote(a.name) === 'USDT' && extractQuote(b.name) !== 'USDT'
                ? -1
                : extractQuote(a.name) !== 'USDT' &&
                  extractQuote(b.name) === 'USDT'
                ? 1
                : 0,
            )
            .sort((a, b) =>
              extractBase(a.name) < extractBase(b.name)
                ? -1
                : extractBase(a.name) > extractBase(b.name)
                ? 1
                : 0,
            )
            .sort((a, b) => {
              if (!Array.isArray(favoriteMarkets) || !favoriteMarkets.length) {
                return 1;
              }
              const aAddy = a.address.toBase58();
              const bAddy = b.address.toBase58();
              return (
                // @ts-ignore
                favoriteMarkets.includes(aAddy) ===
                  favoriteMarkets.includes(bAddy)
                  ? 0
                  : // @ts-ignore
                  favoriteMarkets.includes(aAddy)
                  ? -1
                  : 1
              );
            })
            .map(({ address, name, deprecated }, i) => (
              <Option
                value={address.toBase58()}
                key={i}
                name={name}
                style={{
                  padding: '10px',
                  // @ts-ignore
                  backgroundColor: i % 2 === 0 ? '#1C2222' : '#121616',
                }}
              >
                {1 && (
                  <div className="market-logos">
                    {name
                      .split('/')
                      .map(
                        (market) =>
                          CoinLogos[market] && (
                            <img src={CoinLogos[market]} key={market} />
                          ),
                      )}
                  </div>
                )}
                <span style={{ display: 'flex' }}>
                  {name.split('/')[0]} / {name.split('/')[1]}
                  {/* <span style={{ display: "flex", flex: 1 }}></span>
                    {
                      isSelectOpen.current && isFavoriteMarkets(address.toBase58(), favoriteMarkets)
                        ? <StarIconFav style={{ marginLeft: 8, marginRight: 0, marginTop: 0 }} />
                        : <StarIcon style={{ marginLeft: 8, marginRight: 0, marginTop: 0 }} />
                    } */}
                </span>
              </Option>
            ))}
        </OptGroup>
      </Select>
    </div>
  );
}

const DeprecatedMarketsPage = ({ switchToLiveMarkets }) => {
  return (
    <>
      <Row>
        <Col flex="auto">
          <DeprecatedMarketsInstructions
            switchToLiveMarkets={switchToLiveMarkets}
          />
        </Col>
      </Row>
    </>
  );
};

const RenderLarge = ({ onChangeOrderRef, onPrice, onSize }) => {
  const { connected, wallet } = useWallet();
  const { market } = useMarket();

  const openOrderLoaded = getCache(
    tuple('getOpenOrdersAccounts', wallet, market, connected),
  );

  return (
    <div>
      <Row
        style={{
          minHeight: '460px',
          flexWrap: 'nowrap',
        }}
      >
        <Col flex="auto" style={{ display: 'flex', flexDirection: 'column' }}>
        <Orderbook smallScreen={false} onPrice={onPrice} onSize={onSize} />
        </Col>
        <Col
          flex={'450px'}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <TradeForm
            setChangeOrderRef={onChangeOrderRef}
            style={{ minHeight: 300 }}
          />
          <AssetInformation />
          {connected ? (
            openOrderLoaded ? (
              <StandaloneBalancesDisplay />
            ) : (
              <LoadingTradePageTile />
            )
          ) : (
            <NonConnectedTradePageTile
              message={'Connect your wallet to see your balances'}
            />
          )}
        </Col>
      </Row>
      <Row>
        <Col
          flex={'33%'}
          style={{ height: '399', display: 'flex', flexDirection: 'column' }}
        ></Col>
        <Col
          flex={'34%'}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        ></Col>
        <Col
          flex={'33%'}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        ></Col>
      </Row>
      <Row>
        <UserInfoTable />
      </Row>
    </div>
  );
};

const RenderNormal = ({ onChangeOrderRef, onPrice, onSize }) => {
  const { connected, wallet } = useWallet();
  const { market } = useMarket();

  const openOrderLoaded = getCache(
    tuple('getOpenOrdersAccounts', wallet, market, connected),
  );

  return (
    <div>
      <Row
        style={{
          minHeight: '460px',
          flexWrap: 'nowrap',
        }}
      >
        <Col flex="auto" style={{ display: 'flex', flexDirection: 'column' }}>
          <Orderbook smallScreen={false} onPrice={onPrice} onSize={onSize} />
        </Col>
        <Col
          flex={'34%'}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <TradeForm
            setChangeOrderRef={onChangeOrderRef}
            style={{ minHeight: 300 }}
          />
        </Col>
        <Col
          flex={'450px'}
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <AssetInformation />
          {connected ? (
            openOrderLoaded ? (
              <StandaloneBalancesDisplay />
            ) : (
              <LoadingTradePageTile />
            )
          ) : (
            <NonConnectedTradePageTile
              message={'Connect your wallet to see your balances'}
            />
          )}
        </Col>
      </Row>
      <Row>
        <UserInfoTable />
      </Row>
    </div>
  );
};

const RenderBottom = ({ onChangeOrderRef, onPrice, onSize }) => {
  const { connected, wallet } = useWallet();
  const { market } = useMarket();

  const openOrderLoaded = getCache(
    tuple('getOpenOrdersAccounts', wallet, market, connected),
  );

  return (
    <Row
      style={{
        minHeight: '460px',
        flexWrap: 'nowrap',
      }}
    >
      <Col
        flex={'auto'}
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <Orderbook smallScreen={false} onPrice={onPrice} onSize={onSize} />
        <TradeForm
          setChangeOrderRef={onChangeOrderRef}
          style={{ minHeight: 300 }}
        />
      </Col>
    </Row>
  );
};

const RenderSmall = ({ onChangeOrderRef, onPrice, onSize }) => {
  const { connected, wallet } = useWallet();
  const { market } = useMarket();

  const openOrderLoaded = getCache(
    tuple('getOpenOrdersAccounts', wallet, market, connected),
  );

  return (
    <>
      <Row>
        <Col flex="2" style={{ display: 'flex', flexDirection: 'column' }}>
          <Orderbook smallScreen={true} onPrice={onPrice} onSize={onSize} />
        </Col>
        <Col
          flex="1"
          style={{
            height: '450px',
            display: 'flex',
          }}
        >
          <TradeForm setChangeOrderRef={onChangeOrderRef} />
        </Col>
        <Col flex="1" style={{ display: 'flex', flexDirection: 'column' }}>
          <AssetInformation />
          {connected ? (
            openOrderLoaded ? (
              <StandaloneBalancesDisplay />
            ) : (
              <LoadingTradePageTile />
            )
          ) : (
            <NonConnectedTradePageTile
              message={'Connect your wallet to see your balances'}
            />
          )}
        </Col>
      </Row>
      <Row>
        {/* style={{
          height: '950px',
        }} */}
      </Row>
      <Row>
        <Col flex="auto">
          <UserInfoTable />
        </Col>
      </Row>
    </>
  );
};

const RenderSmaller = ({ onChangeOrderRef, onPrice, onSize }) => {
  const { connected, wallet } = useWallet();
  const { market } = useMarket();

  const openOrderLoaded = getCache(
    tuple('getOpenOrdersAccounts', wallet, market, connected),
  );

  return (
    <>
      <Row
        style={{
          overflow: 'hidden',
        }}
      ></Row>
      <Row>
        <Col flex="auto" style={{ display: 'flex', flexDirection: 'column' }}>
          <Col xs={24} sm={12}>
            <TradeForm
              style={{ flex: 1 }}
              setChangeOrderRef={onChangeOrderRef}
            />
          </Col>
        </Col>
      </Row>
      <Row>
      </Row>
      <Row>
        <Col
          xs={24}
          sm={12}
          style={{ display: 'flex', flexDirection: 'column', height: '250px' }}
        >
          <Orderbook smallScreen={false} onPrice={onPrice} onSize={onSize} />
        </Col>
      </Row>
      <Row>
        <Col flex="auto" style={{ height: '100%' }}>
          <StandaloneBalancesDisplay />
        </Col>
      </Row>
      <Row>
        <Col
          xs={24}
          sm={12}
          style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        >
          <UserInfoTable />
          <AssetInformation />
          {connected ? (
            openOrderLoaded ? (
              <StandaloneBalancesDisplay />
            ) : (
              <LoadingTradePageTile />
            )
          ) : (
            <NonConnectedTradePageTile
              message={'Connect your wallet to see your balances'}
            />
          )}
        </Col>
      </Row>
    </>
  );
};