export async function addOrRemoveFavorite(marketAddress, favoriteMarkets, setFavoriteMarkets) {
    // console.log('All favorite markets: ', favoriteMarkets)
    if (!Array.isArray(favoriteMarkets) || !favoriteMarkets.length) {
        favoriteMarkets = [];
    }

    const isFavorite = favoriteMarkets.filter(market => market === marketAddress);

    if (isFavorite.length) {
        setFavoriteMarkets(favoriteMarkets.filter(fav => fav !== marketAddress));
    } else {
        favoriteMarkets.push(marketAddress);
        setFavoriteMarkets(favoriteMarkets);
    }
}

export function isFavoriteMarkets(marketAddress, favoriteMarkets): Boolean {
    if (!Array.isArray(favoriteMarkets) || !favoriteMarkets.length) {
        return false;
    }
    return favoriteMarkets.find(favMarket => favMarket === marketAddress);
}